import { getOptions } from 'helpers/getOptions';
import { useDispatch } from 'react-redux';
import schedulePageActions from 'store/schedule/schedulePageActions';
import $api from 'http/index';

export const usePublish = () => {
  const dispatch = useDispatch();

  const publish = async (sheetId, workersIds) => {

      const res = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule/publish`,
        {
          sheetIds: sheetId,
          notifyWorkerIds: workersIds
        },
        getOptions()
      );

      dispatch(schedulePageActions.setUnpublishedSchedule(false));
      dispatch(schedulePageActions.setUnpublishedChanges(false));
      return res.data
  };

  return { publish };
};
