export function hexToRgba(hex, alpha = 1) {
  // Remove the '#' if it's present
  hex = hex.replace(/^#/, '');

  // If shorthand (3 characters) convert to 6 characters
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  // Convert the hex to integer (R, G, B)
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}