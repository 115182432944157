export default (theme) => ({
  modal: {
    position: 'fixed',
    bottom: 20,
    left: 'calc(50% + 100px)',
    // right: 0,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none', // Allow clicking the background
    zIndex: 1300,
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '100%',
    maxWidth: '100%',
    height: 50,
    pointerEvents: 'auto', // Enable interaction with the modal itself
  },
  info: {
    flexGrow: 1,
    flexBasis: '33%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  selectedCount: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.textColor.white,
    height: 50,
    width: 50,
    fontSize: '1.5rem',
    borderRadius: '8px 0 0 8px',
  },
  resourceSelected: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: '1.2rem',
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  actionButton: {
    fontSize: '0.8rem',
    fontWeight: '400',
    padding: '5px 10px',

    '&:hover': {
      backgroundColor: theme.palette.background.transparent,
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'nowrap',

      '& > span': {
        margin: 0,
        color: theme.palette.textColor.primary,
      }
    }
  },
  closeIcon: {
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.background.transparent,
    }
  },
  // Worklogs list
  worklogsListBox: {
    position: 'fixed',
    bottom: 90,
    left: 'calc(50% + 100px)',
    // right: 0,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    zIndex: 1300,
    width: 600,
  },
  worklogsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    width: '100%',
    maxWidth: '100%',
    maxHeight: 400,
    padding: '16px 20px',
  },
  worklogsListHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // marginTop: 16,
    // marginLeft: 20,
  },
  worklogsListHeaderText: {
    fontSize: '1.7rem',
    fontWeight: 400,
    pointerEvents: 'none',
    display: 'inline-flex',
  },
  worklogsList: {
    overflow: 'auto',
  },
  worklogsListItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  worklogsListItemIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    '& > svg': {
      width: 20,
      height: 20,
    }
  },
  worklogsListItemInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  worklogsListItemContractor: {
    color: theme.palette.textColor.grey,
  },
  worklogsListItemName: {
    fontWeight: 500,
  },
  worklogsListItemLocation: {
    color: theme.palette.textColor.grey,
  },
  worklogsListItemLocationName: {
    color: 'black',
    fontWeight: 500,
  },
  worklogListItemProjectLocationShowButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.textColor.primary,
    cursor: 'pointer',
    
    '& > p': {
      color: theme.palette.textColor.primary,
    },

    '& > svg': {
      fontSize: '1.1rem',
    }
  },
  worklogListItemCL: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: theme.spacing(1),
  },
  worklogListItemCLLabel: {
    color: theme.palette.textColor.grey,
    marginTop: 2,
  },
  displayFlex: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column'
  },
  justifyContentStart: {
    justifyContent: 'start'
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  gap1: {
    gap: theme.spacing(1),
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 20,
    height: 20,
    transform: 'rotate(135deg)',
    minWidth: 20,
    boxSizing: 'border-box',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 18,
    maxWidth: 18,
    borderRadius: '50%',
    margin: '0 auto',
  },
  username: {
    marginLeft: 6,
    paddingRight: 5,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  resourceActionsLoadingWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
})