import schedulePageActions from './schedulePageActions';
import { endOfDay, format, startOfDay, parseISO } from 'date-fns';
import $api from 'http/index';

import { getOptions } from 'helpers/getOptions';
import { SCHEDULE_TYPES } from 'pages/Schedule/components/SchedulePage/helpers/scheduleType';
import { getMonthDatesByFullWeeksAsKeys } from 'helpers/_helpers';
import { isMobileSchedule } from 'helpers/_helpers';
import { globalBEDateFormat, startEndFromTravelTimeOnlyFormat } from 'common/dateFormatConfig';

export const startEndFromTravelTimeOnly = (createdAt, startTime, timeFormat) => {
  const combinedDate = parseISO(createdAt);

  combinedDate.setHours(parseInt(startTime.hour), parseInt(startTime.minute));

  return format(combinedDate, startEndFromTravelTimeOnlyFormat);
};

export const adaptedDataHelper = (data, timeFormat) => {
  const adaptedData = data.map((el) => ({
    project: el.project,
    sheet: el,
    grid: el.grid,
    workers: el.workers,
    equipment: el.equipment,
    noWorkers: el.workers.length === 0,
    originalStart: el.travelTimeOnly
      ? startEndFromTravelTimeOnly(el.createdAt, el.startTime, timeFormat)
      : el.hours.start,
    originalEnd: el.travelTimeOnly
      ? startEndFromTravelTimeOnly(el.createdAt, el.startTime, timeFormat)
      : el.hours.end,
    start: el.travelTimeOnly
      ? startEndFromTravelTimeOnly(el.createdAt, el.startTime, timeFormat) || '0'
      : el.hours.start,
    end: el.travelTimeOnly
      ? startEndFromTravelTimeOnly(el.createdAt, el.startTime, timeFormat)
      : el.hours.end,
  }));
  return adaptedData;
};

export const setCrewLeader = (data) => async (dispatch, getState) => {
  const { view, sheets } = getState().schedule;
  const isClView = view === SCHEDULE_TYPES.cl;

  try {
    await dispatch(schedulePageActions.setUnpublishedChanges(true));
    if (!isClView) return dispatch(schedulePageActions.setCrewLeaderAction(data));

    dispatch(updateSheets());
  } catch (e) {
    console.log(e);
  }
};

export const getCalendarEvents = (date) => async (dispatch) => {
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/calendar-events?isActiveSchedule=true&from=${date}&to=${date}`,
      {
        ...getOptions(),
      }
    );

    dispatch(schedulePageActions.setCalendarEvents(response.data));
  } catch (e) {
    console.log(e);
  }
};

export const getResourcesTimeoffsAndHolidays = (date) => async (dispatch) => {
  const queryDate = format(new Date(date), globalBEDateFormat);
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/schedule/availability?date=${queryDate}`,
      {
        ...getOptions(),
      }
    );

    dispatch(schedulePageActions.setScheduleResourcesAvailability(response.data));
  } catch (e) {
    console.log(e);
  }
};

export const updateSelectedDate = (date) => (dispatch) => {
  dispatch(schedulePageActions.setSelectedDate(date));
  dispatch(getCalendarEvents(date));
};

export const updateMonthlySelectedDate = (date) => (dispatch) => {
  dispatch(schedulePageActions.setSelectedMonthDate(date));
};

export const addSubmittedSheet = (sheet) => (dispatch, getState) => {
  const { sheets } = getState().schedule;

  const adaptedData = sheets.map((s) =>
    s.sheet._id === sheet._id
      ? {
          ...s,
          sheet: sheet,
        }
      : s
  );
  dispatch(schedulePageActions.setSheets(adaptedData));
};

export const addNewSheet = (sheet) => (dispatch, getState) => {
  const { sheets } = getState().schedule;
  const timeFormat = getState().personalProfile.organization?.settings?.timeFormat;
  dispatch(schedulePageActions.setSheets([...sheets, ...adaptedDataHelper([sheet], timeFormat)]));
};

export const getSheets =
  ({ date, filters, isCrewLeader, isGridView, isMobile }) =>
  async (dispatch, getState) => {
    const timeFormat = getState().personalProfile.organization?.settings?.timeFormat;

    dispatch(schedulePageActions.setLoading(true));
    try {
      const searchParams = new URLSearchParams();

      if (Object.keys(filters).length) {
        Object.entries(filters).map(([key, value]) => searchParams.append(key, value));
      }
      // isCrewLeader && searchParams.append('isCrewLeader', isCrewLeader);
      if (isGridView) {
        searchParams.append('gridDate', date);
      } else {
        searchParams.append('scheduleDate', date);
        if (isMobile) searchParams.append('sortBy', 'mobile');
      }
      //! for mobile we need add param - sortBy='mobile';
      const response = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule?${searchParams}`,
        {
          ...getOptions(),
        }
      );

      const adaptedData = adaptedDataHelper(response.data, timeFormat);

      if (!isCrewLeader) {
        dispatch(schedulePageActions.setSheets(adaptedData));
      } else {
        const clData = adaptedData.reduce((acc, el) => {
          const crewLeaders = el.workers.filter((worker) => worker.foreman);

          if (crewLeaders.length) {
            crewLeaders.forEach((worker) => {
              if (worker.hours?.length) {
                worker.hours.forEach((hours) => {
                  acc.push({
                    ...el,
                    cl: worker,
                    individualTime: hours,
                  });
                });
              } else {
                acc.push({ ...el, cl: worker });
              }
            });
          } else {
            acc.push(el);
          }
          return acc;
        }, []);
        dispatch(schedulePageActions.setSheets(clData));
      }
    } catch (e) {
      console.error(e);
    }
    dispatch(schedulePageActions.setLoading(false));
  };

export const getMonthSheets =
  ({ filters }) =>
  async (dispatch, getState) => {
    const { currentMonthDate } = getState().schedule;
    const { weekStart } = getState().personalProfile.organization.settings;
    dispatch(schedulePageActions.setLoading(true));

    try {
      const searchParams = new URLSearchParams();

      if (Object.keys(filters).length) {
        Object.entries(filters).map(([key, value]) => searchParams.append(key, value));
      }
      const { startOfWeekDay, endOfWeekDay } = getMonthDatesByFullWeeksAsKeys(
        currentMonthDate,
        weekStart === 'Sun' ? 0 : 1,
        new Date(),
        true
      );
      searchParams.append('from', startOfWeekDay.toISOString());
      searchParams.append('to', endOfWeekDay.toISOString());
      searchParams.append('groupBy', 'date');

      const response = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule?${searchParams}`,
        {
          ...getOptions(),
        }
      );

      dispatch(schedulePageActions.setMonthSheets(response.data));
    } catch (e) {
      console.error(e);
    }
    dispatch(schedulePageActions.setLoading(false));
  };

export const getEquipment = () => async (dispatch) => {
  dispatch(schedulePageActions.setLoading(true));
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/equipment?isActiveSchedule=true`,
      {
        ...getOptions(),
      }
    )

    dispatch(schedulePageActions.setResourcesEquipment(response.data));
  } catch (e) {
    console.log(e);
    dispatch(schedulePageActions.setResourcesEquipment([]));
  }
  dispatch(schedulePageActions.setLoading(false));
};

export const getWorkers = () => async (dispatch) => {
  dispatch(schedulePageActions.setLoading(true));
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/users?activeSchedule=true`, {
      ...getOptions(),
    });

    dispatch(schedulePageActions.setResourcesAllWorkers(response.data));
    dispatch(
      schedulePageActions.setResourcesWorkers(
        response.data?.filter((user) => user.profile.shifts.timeOfDay !== 'Inactive')
      )
    );
  } catch (e) {
    console.log(e);
    dispatch(schedulePageActions.setResourcesWorkers([]));
  }
  dispatch(schedulePageActions.setLoading(false));
};

export const reconcile =
  ({ viewType, gridDate, sheetIds, onError, date, onSuccess }) =>
  async (dispatch, getState) => {
    const data = {
      sheetIds,
    };

    if (date) {
      data.date = format(new Date(date), globalBEDateFormat);
    }
    if (gridDate) {
      data.gridDate = gridDate;
    }

    try {
      const response = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule/reconcile`,
        data,
        getOptions()
      );

      // dispatch(updateSheets());
      dispatch(schedulePageActions.reconcileWorklogs({ sheetIds: sheetIds }));
      onSuccess();
    } catch (e) {
      console.log(e);
      onError();
    }
  };

export const sendReconcileEmail =
  ({ isGridView, date, onError }) =>
  async (dispatch) => {
    try {
      const response = await $api.post(`${process.env.REACT_APP_BASE_URL}/emails/reconciledEmail`,
      { isGridView, date },
      {
        ...getOptions(),
      });

    } catch (e) {
      console.log(e);
      onError();
    }
  };

export const publish = (sheetIds) => async (dispatch) => {
  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/schedule/publish
        `,
      sheetIds,
      {
        ...getOptions(),
      }
    );

    dispatch(schedulePageActions.setUnpublishedSchedule(false));
    dispatch(
      schedulePageActions.publishWorklogs({
        sheetIds: sheetIds.sheetIds,
      })
    );
  } catch (e) {
    throw new Error(e?.response?.data?.message || e.message);
  }
};

export const updateSheetAddResource =
  (sheetId, isGenerateSheetObjects) => async (dispatch, getState) => {
    const { sheets, selectedDate } = getState().schedule;

    const searchParams = new URLSearchParams();
    searchParams.append('utcStartDate', startOfDay(selectedDate).toISOString());
    searchParams.append('utcEndDate', endOfDay(selectedDate).toISOString());
    searchParams.append('isGenerateSheetObjects', isGenerateSheetObjects);

    try {
      const response = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}?${searchParams}`,
        {
          ...getOptions(),
        }
      )

      dispatch(
        schedulePageActions.setSheets(
          sheets.map((s) =>
            s.sheet._id === sheetId
              ? {
                  project: response.data.project,
                  sheet: response.data,
                  grid: response.data.grid,
                  workers: response.data.workers,
                  equipment: response.data.equipment,
                  noWorkers: response.data.workers.length === 0,
                  start: response.data.hours.start,
                  end: response.data.hours.end,
                  originalStart: response.data.hours.start,
                  originalEnd: response.data.hours.end,
                  cl: response.data.cl,
                }
              : s
          )
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

export const updateSheets = () => async (dispatch, getState) => {
  const { selectedDate, filters, view } = getState().schedule;
  const isCrewLeader = view === SCHEDULE_TYPES.cl;
  const date = format(selectedDate, globalBEDateFormat);
  const isGridView = view === SCHEDULE_TYPES.grid;
  const isMobile = isMobileSchedule();
  dispatch(
    getSheets({
      date,
      filters,
      isCrewLeader,
      isGridView,
      isMobile: isMobile,
    })
  );
};

export const removeResources =
  ({ sheetId, workers, equipment }) =>
  async (dispatch, getState) => {
    const resourcesType = workers ? 'workers' : 'equipment';
    const resourceId = workers ? workers : equipment;

    try {
      const response = await $api.delete(
        `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/${resourcesType}/${resourceId}
`,
        getOptions()
      );

      const sheets = getState().schedule.sheets;
      dispatch(
        schedulePageActions.setSheets(
          sheets
            // .filter((s) => !workerIds || !workerIds.includes(s.cl))
            .map((s) =>
              s._id === sheetId
                ? {
                    ...s,
                    workers: workers ? s.workers.filter((w) => w._id !== workers) : s.workers,
                    equipment: equipment
                      ? s.equipment.filter((w) => w._id !== equipment)
                      : s.equipment,
                    sheet: {
                      ...s.sheet,
                      workers: workers ? s.workers.filter((w) => w._id !== workers) : s.workers,
                      equipment: equipment
                        ? s.equipment.filter((w) => w._id !== equipment)
                        : s.equipment,
                    },
                  }
                : s
            )
        )
      );
    } catch (e) {
      console.log(e);
    }
  };


export const getFutureSheets = async ({ searchValue, skip, limit }) => {
  const from = startOfDay(new Date());

  const optionalParams = {
    searchValue,
    from,
    skip,
    limit,
    sortBy: 'contractor',
  };

  const options = {
    ...getOptions(),
    params: optionalParams,
  };

  try {
    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets`, options);

    return { sheets: res?.data, hasMore: res?.data?.length === limit };
  } catch (e) {
    console.log(e);
  }
}