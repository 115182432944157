import { parseISO, startOfDay } from 'date-fns';
import schedulePageActions from './schedulePageActions';
import { SCHEDULE_TYPES } from 'pages/Schedule/components/SchedulePage/helpers/scheduleType';
import { createReducer } from '@reduxjs/toolkit';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/components/ScheduleHeader/components/DateTypeSelector/constants';

const initialState = {
  loading: 0,
  view: SCHEDULE_TYPES.time,
  selectedDate: sessionStorage.getItem('scheduleDate')
    ? parseISO(sessionStorage.getItem('scheduleDate'))
    : startOfDay(new Date()),
  resources: {
    allWorkers: null,
    equipment: null,
    workers: null,
  },
  filters: {},
  filterOptions: { contractors: [], projects: [] },
  sheets: [],
  calendarEvents: [],
  unpublishedSchedule: false,
  unpublishedChanges: false,
  calendarTypeView: CALENDAR_VIEW_TYPES.daily,
  monthSheets: [],
  currentMonthDate: startOfDay(new Date()),
  selectedMonthDayDate: '',
  availability: {
    timeoffs: [],
    calendarEvents: [],
    holidays: [],
  },
  selectedResources: {
    sheetId: null,
    workers: [],
    equipment: [],
  }
};

const schedule =  createReducer(initialState, {
    [schedulePageActions.setLoading]: (state, { payload }) => ({
      ...state,
      loading: payload ? state.loading + 1 : (state.loading || 1) - 1,
    }),
    [schedulePageActions.setScheduleView]: (state, { payload }) => ({
      ...state,
      view: Object.values(SCHEDULE_TYPES).includes(payload) ? payload : SCHEDULE_TYPES.time,
    }),
    [schedulePageActions.setResourcesEquipment]: (state, { payload }) => ({
      ...state,
      resources: { ...state.resources, equipment: payload },
    }),
    [schedulePageActions.setResourcesWorkers]: (state, { payload }) => ({
      ...state,
      resources: { ...state.resources, workers: payload },
    }),
    [schedulePageActions.setResourcesAllWorkers]: (state, { payload }) => ({
      ...state,
      resources: { ...state.resources, allWorkers: payload },
    }),
    [schedulePageActions.addWorker]: (state, { payload }) => ({
      ...state,
      resources: {
        ...state.resources,
        workers: [...state.resources.workers, payload],
      }
    }),
    [schedulePageActions.addToAllWorkers]: (state, { payload }) => ({
      ...state,
      resources: {
        ...state.resources,
        allWorkers: [...state.resources.allWorkers, payload],
      }
    }),
    [schedulePageActions.updateWorkerResource]: (state, { payload }) => ({
      ...state,
      resources: {
        ...state.resources,
        workers: state.resources.workers.map(
          (worker) => (
            worker._id === payload._id
              ? { ...worker, profile: { ...worker.profile, ...(payload.profile || {}) } }
              : worker
          )
        ),
        allWorkers: state.resources.allWorkers.map(
          (worker) => (
            worker._id === payload._id
              ? { ...worker, profile: { ...worker.profile, ...(payload.profile || {}) } }
              : worker
          )
        ),
      }
    }),
    [schedulePageActions.removeWorkerResource]: (state, { payload }) => ({
      ...state,
      resources: {
        ...state.resources,
        workers: [...state.resources.workers.filter(worker => worker._id !== payload._id)],
      }
    }),
    [schedulePageActions.setSheets]: (state, { payload }) => {
      const sheetsWithResources = payload.filter((s) => !s.noWorkers);
      const isEveryPublished = sheetsWithResources.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isEveryPublished || sheetsWithResources.length === 0;

      return {
        ...state,
        sheets: payload,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: sheetsWithResources.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.setMonthSheets]: (state, { payload }) => {

      return {
        ...state,
        monthSheets: payload,
      };
    },
    [schedulePageActions.addSheetToMonthSchedule]: (state, { payload }) => {
      const updatedMonthSheets = { ...state.monthSheets };
      const { dateKey, data } = payload;
    
      const updatedData = updatedMonthSheets[dateKey] ? [...updatedMonthSheets[dateKey], data] : [data];

      return {
        ...state,
        monthSheets: {
          ...updatedMonthSheets,
          [dateKey]: updatedData,
        },
      };
    },
    [schedulePageActions.removeSheetFromMonthSchedule]: (state, { payload }) => {// _id
      const filteredData = {};

      for (const key in state.monthSheets) {
        if (Object.hasOwnProperty.call(state.monthSheets, key)) {
          filteredData[key] = state.monthSheets[key].filter(obj => obj._id != payload);
        }
      }

      return {
        ...state,
        monthSheets: filteredData
      };
    },
    [schedulePageActions.updateMonthSheet]: (state, { payload }) => {
      const updatedMonthSheets = { ...state.monthSheets };
    
      for (const key in updatedMonthSheets) {
        const array = updatedMonthSheets[key];
        const updatedArray = array.map(obj => {
          if (obj._id === payload._id) {
            return { ...obj, ...payload };
          }
          return obj;
        });
    
        updatedMonthSheets[key] = updatedArray;
      }
    
      return {
        ...state,
        monthSheets: updatedMonthSheets,
      };
    },
    [schedulePageActions.updateCrewLeaderMonthCalendar]: (state, { payload }) => {
      const updatedMonthSheets = { ...state.monthSheets };
    
      for (const key in updatedMonthSheets) {
        const array = updatedMonthSheets[key];
        const updatedArray = array.map(obj => {
          if (obj._id === payload._id) {
            return { ...obj, workers: obj.workers.map(worker => {
              if (worker._id !== payload.workerId) return worker;
              return {...worker, foreman: payload.foreman}
            }) };
          }
          return obj;
        });
    
        updatedMonthSheets[key] = updatedArray;
      }
    
      return {
        ...state,
        monthSheets: updatedMonthSheets,
      };
    },
    
    [schedulePageActions.setFilters]: (state, { payload }) => ({ ...state, filters: payload }),
    [schedulePageActions.setFilterOptions]: (state, { payload }) => ({
      ...state,
      filterOptions: { ...state.filterOptions, ...payload },
    }),
    [schedulePageActions.setSelectedDate]: (state, { payload }) => ({
      ...state,
      selectedDate: payload,
      sheets: initialState.sheets,
    }),
    [schedulePageActions.setSelectedMonthDate]: (state, { payload }) => ({
      ...state,
      currentMonthDate: payload,
    }),
    [schedulePageActions.setSelectedMonthDayDate]: (state, { payload }) => ({
      ...state,
      selectedMonthDayDate: payload,
    }),
    [schedulePageActions.setUnpublishedSchedule]: (state, { payload }) => ({
      ...state,
      unpublishedSchedule: payload,
    }),
    [schedulePageActions.setUnpublishedChanges]: (state, { payload }) => ({
      ...state,
      unpublishedChanges: state.unpublishedSchedule || payload,
    }),
    [schedulePageActions.setCrewLeaderAction]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el.sheet._id === payload.sheetId) {
          // el.workers.find((el) => el._id === payload.workerId).foreman = payload.isForeman;
          // el.sheet.unpublishedChanges = true;
          const newWorkersArr = el.workers.map(worker=>{
            if (worker._id === payload.workerId){
              return {...worker, foreman: payload.isForeman, }
            }
            return worker
          })
          return {...el, workers:newWorkersArr, sheet: {...el.sheet, unpublishedChanges: true}}
        }
        return el;
      });
      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.moveWorkers]: (state, { payload }) => {
      const newSheets = state.sheets.map((scheduleSheet) => {
        const updatedSheet = payload.find((sheet) => sheet._id === scheduleSheet.sheet._id);
        return updatedSheet
          ? {
              ...scheduleSheet,
              sheet: {
                ...scheduleSheet.sheet,
                workers: updatedSheet.workers,
              },
              workers: updatedSheet.workers,
              noWorkers: updatedSheet.workers.length === 0,
            }
          : scheduleSheet;
      });

      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.moveEquipment]: (state, { payload }) => {
      const newSheets = state.sheets.map((scheduleSheet) => {
        const newSheet =
        payload.fromId && scheduleSheet.sheet._id === payload.fromId
            ? {
                ...scheduleSheet,
                equipment: scheduleSheet.equipment.filter((e) => e._id !== payload.equipmentId),
              }
            : scheduleSheet;
        const updatedSheet = payload.data.find((sheet) => sheet._id === newSheet.sheet._id);
        return updatedSheet
          ? {
              ...scheduleSheet,
              sheet: {
                ...scheduleSheet.sheet,
                equipment: updatedSheet.equipment,
              },
              equipment: updatedSheet.equipment,
            }
          : newSheet;
      });

      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.updateSheet]: (state, { payload }) => {
      const newSheets = state.sheets.map((scheduleSheet) =>
        scheduleSheet.sheet._id === payload._id
          ? ({
              project: payload.project,
              sheet: payload,
              grid: payload.grid,
              workers: payload.workers,
              equipment: payload.equipment,
              noWorkers: payload.workers.length === 0,
              originalStart: payload.hours.start,
              originalEnd: payload.hours.end,
              start: payload.hours.start,
              end: payload.hours.end,
            })
          : scheduleSheet
      );
      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;

      return {
        ...state,
        sheets: newSheets,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.setReturnToShop]: (state, { payload }) => {
      const sheetsThisDay = state.sheets.map((scheduleSheet) =>
        scheduleSheet.sheet._id === payload._id
          ? {
              ...scheduleSheet,
              sheet: {
                ...scheduleSheet.sheet,
                returnToShop: payload.returnToShop,
                unpublishedChanges: true,
              },
            }
          : scheduleSheet
      );
      const filteredSheetsThisDay = sheetsThisDay.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;

      return {
        ...state,
        sheets: sheetsThisDay,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.updateCrewLeader]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el.sheet._id === payload._id) {

            const updatedSheet = {
              ...el.sheet,
              workers: el.sheet.workers.map((worker) => {
                if (worker._id === payload.workerId) {
                  return {
                    ...worker,
                    foreman: payload.foreman,
                  };
                }
                return worker;
              }),
              unpublishedChanges: true,
            };
  
            return {
              ...el,
              sheet: updatedSheet,
              workers: el.sheet.workers.map((worker) => {
                if (worker._id === payload.workerId) {
                  return {
                    ...worker,
                    foreman: payload.foreman,
                  };
                }
                return worker;
              }),
            };
          }

        return el;
      });
      
      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;

      return {
        ...state,
        sheets: newSheets,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.updateSheetsEquipment]: (state, { payload }) => {
      const newSheets = state.sheets.map(el => {
        const hasUpdatedEquip = el.sheet.equipment?.some(equip => equip._id === payload._id);
        if (hasUpdatedEquip) {
          el.sheet.equipment = el.sheet.equipment.map(equip => equip._id === payload._id ? ({ ...equip, ...payload }) : equip);
        }
      });

      return {
        ...state,
        sheets: newSheets,
      }
    },
    [schedulePageActions.setSheetCanceled]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el._id === payload._id) {
          el.sheet.canceledAt = payload.canceledAt;
          el.sheet.canceledBy = payload.canceledBy;
          el.sheet.cancelNote = payload.cancelNote;

          if (!el.sheet.unpublishedChanges && !payload.canceledAt) {
            el.sheet.unpublishedChanges = true;
          }
        }
        return el;
      });
      
      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;

      return {
        ...state,
        sheets: newSheets,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.setSheetSubmitted]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el._id === payload._id) {
          el.sheet.submittedAt = payload.submittedAt;
          el.sheet.submittedBy = payload.submittedBy;
        }
        return el;
      });
      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.unpublishWorklog]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el._id === payload._id) {
          el.sheet.unpublishedChanges = true;
        }
        return el;
      });

      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;

      return {
        ...state,
        sheets: newSheets,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.publishWorklogs]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (payload.sheetIds.includes(el.sheet._id)) {
          return {
            ...el,
            sheet: {
              ...el.sheet,
              published: true,
              unpublishedChanges: false,
            }
          }
        }
        return el;
      });

      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;
      return {
        ...state,
        sheets: newSheets,
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    [schedulePageActions.reconcileWorklogs]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (payload.sheetIds.includes(el._id)) {
          el.sheet.reconciled = true;
        }
        return el;
      });
      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.setGridPosition]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el.sheet._id === payload.id) {
          const newGrid = { ...el.grid, position: payload.position };
          return {
            ...el,
            grid: { ...newGrid},
            sheet: { ...el.sheet, grid: { ...newGrid } }
          }
        }
        return el;
      });

      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.swapGridsPosition]: (state, { payload }) => {
      const newSheets = state.sheets.map((el) => {
        if (el.sheet._id === payload.swappedGrids[0]._id) {
          return {
            ...el,
            sheet: {
              ...el.sheet,
              grid: { ...el.sheet.grid, position: payload.swappedGrids[0].position },
            },
            grid: { ...el.sheet.grid, position: payload.swappedGrids[0].position }
          };
        }
        if (el.sheet._id === payload.swappedGrids[1]._id) {
          return {
            ...el,
            sheet: {
              ...el.sheet,
              grid: { ...el.sheet.grid, position: payload.swappedGrids[1].position },
            },
            grid: { ...el.sheet.grid, position: payload.swappedGrids[1].position }
          };
        }
        return el;
      });

      return { ...state, sheets: newSheets };
    },
    [schedulePageActions.deleteWorklog]: (state, { payload }) => {
      const newSheets = state.sheets.filter((el) => el._id !== payload._id);

      const filteredSheetsThisDay = newSheets.filter((s) => !s.noWorkers);
      const isPublished = filteredSheetsThisDay.every((s) => s.sheet.published);
      const isScheduleUnpublished = !isPublished || filteredSheetsThisDay.length === 0;
      return {
        ...state,
        sheets: [...newSheets],
        unpublishedSchedule: isScheduleUnpublished,
        unpublishedChanges: filteredSheetsThisDay.some((s) => s.sheet.unpublishedChanges),
      };
    },
    // Calendar events sockets
    [schedulePageActions.setCalendarEvents]: (state, { payload }) => ({
      ...state,
      calendarEvents: payload,
    }),
    [schedulePageActions.addCalendarEvent]: (state, { payload }) => ({
      ...state,
      calendarEvents: [...state.calendarEvents, payload],
    }),
    [schedulePageActions.removeCalendarEvents]: (state, { payload }) => ({
      ...state,
      calendarEvents: state.calendarEvents.filter(({ _id }) => _id !== payload)
    }),
    [schedulePageActions.setCalendarTypeView]: (state, { payload }) => ({
      ...state,
      calendarTypeView: payload,
    }),
    [schedulePageActions.setScheduleResourcesAvailability]: (state, { payload }) => ({
      ...state,
      availability: payload,
    }),
    [schedulePageActions.setSelectedResourcesWorklogId]: (state, { payload }) => ({
      ...state,
      selectedResources: {
        ...state.selectedResources,
        sheetId: payload,
        // workers: [],
        // equipment: [],
      }
    }),
    [schedulePageActions.selectWorklogPeopleResource]: (state, { payload }) => {
      const workers = state.selectedResources.workers?.includes(payload)
        ? state.selectedResources.workers.filter(workerId => workerId !== payload)
        : [...state.selectedResources.workers, payload];

      return {
        ...state,
        selectedResources: {
          ...state.selectedResources,
          sheetId: (state.selectedResources.equipment?.length || workers?.length) ? state.selectedResources.sheetId : null,
          workers: workers,
        }
      }
    },
    [schedulePageActions.selectWorklogEquipmentResource]: (state, { payload }) => {
      const equipment = state.selectedResources.equipment?.includes(payload)
        ? state.selectedResources.equipment.filter(equipId => equipId !== payload)
        : [...state.selectedResources.equipment, payload];

      return {
        ...state,
        selectedResources: {
          ...state.selectedResources,
          sheetId: (state.selectedResources.workers?.length || equipment?.length) ? state.selectedResources.sheetId : null,
          equipment: equipment
      }
      }
    },
    [schedulePageActions.setSelectedResources]: (state, { payload }) => ({
      ...state,
      selectedResources: {
        ...state.selectedResources,
        ...payload,
      }
    }),
    [schedulePageActions.deselectAllResources]: (state, { payload }) => ({
      ...state,
      selectedResources: {
        sheetId: null,
        workers: [],
        equipment: [],
      }
    })
  })

export default schedule;
//   const defaultCase = () => state;

//   return (nextState[action.type] || defaultCase)(action.payload);
// };
