import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { moveBunchResources, duplicateBunchResources, removeBunchResources } from './apiCalls';
import { OverlapsingModal } from 'components/WorklogDetails/components/OverlapsDuplicateModal/OverlapsingModal';
import schedulePageActions from 'store/schedule/schedulePageActions';

export const useResourceActions = (openSnackbar, closeActionsBar, dispatch) => {
  const [modal, setModal] = useState({ data: null, reason: null });
  const [isLoading, setIsLoading] = useState(false);
  const selectedResources = useSelector((state) => state.schedule.selectedResources);

  const setOverlappingModal = (data) => {
    dispatch(schedulePageActions.setSelectedResources({
      workers: selectedResources.workers?.filter(wId => data?.data?.[0]?.workerOverlapses?.some(({ worker }) => worker._id === wId)),
      equipment: selectedResources.equipment?.filter(eId => data?.data?.[0]?.equipmentOverlapses?.some(({ equipment }) => equipment._id === eId)),
    }))
    setModal(data);
  }

  const handleMove = useCallback((targetSheetId) => {
    setIsLoading(true)

    return moveBunchResources(selectedResources, targetSheetId, setOverlappingModal, openSnackbar)
      .then(closeActionsBar)
      .finally(() => setIsLoading(false))
  }, [selectedResources]);

  const handleDuplicate = useCallback((targetSheetId) => {
    setIsLoading(true)

    return duplicateBunchResources(selectedResources, targetSheetId, setModal, openSnackbar)
      .then(closeActionsBar)
      .finally(() => setIsLoading(false))
  }, [selectedResources]);

  const handleRemove = useCallback(() => {
    setIsLoading(true)

    return removeBunchResources(selectedResources, openSnackbar)
      .then(closeActionsBar)
      .finally(() => setIsLoading(false))
  }, [selectedResources]);

  const closeOverlappingModal = () => {
    setModal({ data: null, reason: null });
  }
  const saveOverlapping = () => {
    setModal({ data: null, reason: null });
    closeActionsBar();
  }

  const duplicateModal = useCallback(() => {
    if (modal?.data)
      return (
        <OverlapsingModal
          data={modal.data}
          message={modal.reason}
          onClose={closeOverlappingModal}
          onSave={saveOverlapping}
        />
      );
  }, [modal]);

  return {
    OverlappingModalComponent: modal?.data ? duplicateModal : () => null,
    setOverlappingModal: setModal,
    handleMove,
    handleDuplicate,
    handleRemove,
    isLoading,
  };
};
