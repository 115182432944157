import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';

export const moveBunchResources = async (data, sheetId, setOverlappingModal, openSnackbar) => {
  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/resources/move`,
      data,
      {
        ...getOptions(),
      }
    );
    openSnackbar('success', 'Resources has been moved successfully');
  } catch (error) {
    if (error?.response?.data?.type === 'range-sheet-time-overlapse') {
      setOverlappingModal({
        reason: error.response.data.message,
        data: error.response.data.data,
      });
    }

    openSnackbar('error', error?.response?.data?.message || error.message);
    throw error;
  }
}


export const duplicateBunchResources = async (data, sheetId, setOverlappingModal, openSnackbar) => {
  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/resources/duplicate`,
      data,
      {
        ...getOptions(),
      }
    );
    openSnackbar('success', 'Resources has been duplicated successfully');
  } catch (error) {
    if (error?.response?.data?.type === 'range-sheet-time-overlapse') {
      setOverlappingModal({
        reason: error.response.data.message,
        data: error.response.data.data,
      });
    }

    openSnackbar('error', error?.response?.data?.message || error.message);
    throw error;
  }
}


export const removeBunchResources = async (data, openSnackbar) => {
  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/resources/remove`,
      data,
      {
        ...getOptions(),
      }
    );
    openSnackbar('success', 'Resources has been removed successfully');
  } catch (error) {
    openSnackbar('error', error?.response?.data?.message || error.message);
    throw error;
  }
}