import React, { useMemo } from 'react';
import DayPicker from 'react-day-picker';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { differenceInCalendarDays, addMonths } from 'date-fns';
import classnames from 'classnames';

import { getPrevDay } from '../Kiosk/helpers/getPrevDay';

import 'react-day-picker/lib/style.css';
import styles from './styles';
import './styles.css';
import { useWeekStart } from 'hooks/useWeekStart';

const DatePicker = ({
  classes,
  toggleCalendar,
  value,
  handleSelect,
  showPeriod,
  normalPeriodMode,
  startValue,
  handleReset,
  minDate,
  isInfinite,
}) => {
  const period = useMemo(() => differenceInCalendarDays(value, startValue), [startValue, value]);
  const toMonth = useMemo(() => (
    isInfinite ? undefined : addMonths(value, 1)
  ), [value, startValue, isInfinite]);
  const { weekDayFromO } = useWeekStart();
  return (
    <div className={classes.datePickerWrapper}>
      <IconButton
        className={classes.closeButton}
        onClick={toggleCalendar}
        aria-label="Close"
        disableRipple
      >
        <CloseIcon />
      </IconButton>

      <DayPicker
        className="Selectable"
        selectedDays={
          showPeriod
            ? [startValue, { from: startValue, to: normalPeriodMode ? value : getPrevDay(value) }]
            : value
        }
        disabledDays={{ before: showPeriod ? startValue : (minDate || new Date()) }}
        fromMonth={minDate || new Date()}
        toMonth={toMonth}
        initialMonth={startValue}
        onDayClick={handleSelect}
        firstDayOfWeek={weekDayFromO}
      />

      {showPeriod && (
        <div className={classes.btnHolder}>
          <div className={classes.inlineFlex}>
            <Typography className={classes.selectedCount}>
              {normalPeriodMode ? period + 1 : period} {period === 1 ? 'day' : 'days'} selected
            </Typography>
            <IconButton
              color="secondary"
              variant="text"
              className={classnames(classes.extraBtn, classes.selectBtn)}
              onClick={handleReset}
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(DatePicker);
