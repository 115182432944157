import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PageLoader from 'components/PageLoader';
import SubmitDetails from './components/SubmitDetails';
import SignatureModal from './components/SignatureModal';
import styles from './styles';
import OutsideClockInModal from './components/OutsideClockInModal';
import { useCheckOutsideClockIn } from '../../hooks/useCheckOutsideClockIn';
import { emitEvent } from 'helpers/emitEvent.sockets';
import { SHEET_EVENTS } from 'pages/Schedule/components/ScheduleCalendar/helpers/sheetEvents.sockets';
import { EVENT_NAMESPACES } from 'helpers/eventNamespaces.sockets';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import currentWorklogActions from 'store/currentWorklog/actions';
import { useDispatch } from 'react-redux';
import { addSubmittedSheet } from 'store/schedule/schedulePageOperation';
import worklogsPageActions from 'store/worklogs/worklogsPageActions';
import { v4 as uuidv4 } from 'uuid';

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const validation = (values) => {
  const correctEmail = 'Enter valid email address';
  const requiredFieldError = "This field can't be blank";
  const numberError = 'Phone number must be 10 digits';

  const errors = {};
  if (values.contractorSignature && !values.contractorName.trim()) {
    errors.contractorName = "This field can't be blank if there is a signature.";
  }
  if (values.contractorEmail?.trim() && !validateEmail(values.contractorEmail)) {
    errors.contractorEmail = correctEmail;
  }
  if (
    values.contractorPhoneNumber?.trim() &&
    !(values.contractorPhoneNumber.trim()?.length === 10)
  ) {
    errors.contractorPhoneNumber = numberError;
  }

  const dotContacts = {};
  values.dot.forEach((el, i) => {
    const tmpErrObj = {};
    // if (!el.name.trim()) {
    //   tmpErrObj.name = requiredFieldError;
    // }

    if (el.email.trim() && !validateEmail(el.email)) {
      tmpErrObj.email = correctEmail;
    }

    if (el.phoneNumber.trim() && !(el.phoneNumber.trim()?.length === 10)) {
      tmpErrObj.phoneNumber = numberError;
    }
    if (Object.keys(tmpErrObj).length) {
      dotContacts[i] = tmpErrObj;
    }
    if (
      dotContacts[0] &&
      !values.dot?.[0]?.name?.trim() &&
      !values.dot?.[0]?.email?.trim() &&
      !values.dot?.[0]?.phoneNumber?.trim()
    ) {
      delete dotContacts[0];
    }
  });

  if (Object.keys(dotContacts).length) {
    errors.dot = dotContacts;
  }

  const contacts = {};
  values.contractorAlternateContacts.forEach((el, i) => {
    const tmpErrObj = {};
    if (!el.name.trim()) {
      tmpErrObj.name = requiredFieldError;
    }

    if (el.email.trim() && !validateEmail(el.email)) {
      tmpErrObj.email = correctEmail;
    }

    if (el.phoneNumber.trim() && !(el.phoneNumber.trim()?.length === 10)) {
      tmpErrObj.phoneNumber = numberError;
    }
    if (Object.keys(tmpErrObj).length) {
      contacts[i] = tmpErrObj;
    }
  });

  if (Object.keys(contacts).length) {
    errors.contractorAlternateContacts = contacts;
  }

  return errors;
};

const SubmitModal = (props) => {
  const dispatch = useDispatch();
  const [signatureModal, setSignatureModal] = useState({
    isOpen: false,
    type: 'dot',
  });

  const [outsideClockInModal, setOutsideClockInModal] = useState(false);
  const [contractorsAlternateContacts, setContractorsAlternateContacts] = useState([]); //fully alternate contacts obj
  const [contactDropdownSelector, setContactDropdownSelector] = useState(false);

  useEffect(() => {
    setContractorsAlternateContacts(sheet?.alternateContacts || []);
  }, [props.sheet]);

  const openSignatureModal = (type, i) => () => {
    setSignatureModal({ type, isOpen: true });
  };

  const closeSignatureModal = () => {
    setSignatureModal((prev) => ({ ...prev, isOpen: false }));
  };

  const closeOutsideClockInModal = (setSubmitting) => () => {
    setOutsideClockInModal(null);
    setSubmitting(false);
  };

  const contactsAdapter = (contactsArr) => {
    return contactsArr.map((el) => ({
      name: el.name,
      email: el.email,
      phoneNumber: el.phoneNumber,
      image: el.Signature,
    }));
  };

  const submitOutsideClockInModal = (setSubmitting, values) => async () => {
    const data = {
      notes: values.notes.trim(),
    };
    const dot = contactsAdapter([...values.dot]);
    const contractor = [
      {
        name: values.contractorName,
        email: values.contractorEmail,
        image: values.contractorSignature,
        phoneNumber: values.contractorPhoneNumber,
      },
      ...contactsAdapter([...values.contractorAlternateContacts]),
    ];
    data.dot = dot;
    data.contractor = contractor;
    setOutsideClockInModal(null);

    try {
      setSubmitting(true);
      const res = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/sheets/${sheet._id}/submit`,
        data,
        getOptions()
      );
      dispatch(worklogsPageActions.setForceUpdate(true));
      dispatch(currentWorklogActions.setWorklogSheet(res.data));
      dispatch(addSubmittedSheet(res.data));
      emitEvent(EVENT_NAMESPACES.sheets, SHEET_EVENTS.submit, sheet._id);
      openSnackbar('success', 'Successfully Submitted!');
      closeModal();
    } catch (e) {
      openSnackbar('error', e?.response?.data?.message?.join('\n') || e.mesage);
      // openSnackbar('error', 'On Job Time is not within Clock-in Time range');
    } finally {
      setSubmitting(false);
    }
  };

  const setSignature = (type, setFieldValue) => (data) => {
    setFieldValue(`${type}Signature`, data);
    closeSignatureModal();
  };

  const removeSignature = (fieldName, setFieldValue) => () => {
    setFieldValue(`${fieldName}`, null);
  };

  const handleAppendDOTContact = (setFieldValue, values) => {
    setFieldValue('dot', [
      ...values.dot,
      {
        name: '',
        email: '',
        phoneNumber: '',
        new: true,
        _id: uuidv4(),
      },
    ]);
  };

  const handleAppendContact = (setFieldValue, values) => (e) => {
    const { value } = e?.target;
    if (value === 'newEmpty') {
      setFieldValue('contractorAlternateContacts', [
        ...values.contractorAlternateContacts,
        {
          name: '',
          title: '',
          email: '',
          phoneNumber: '',
          new: true,
          _id: uuidv4(),
        },
      ]);
      setContactDropdownSelector(false);
      return;
    }

    setFieldValue('contractorAlternateContacts', [
      ...values.contractorAlternateContacts,
      contractorsAlternateContacts.find((el) => el._id === value),
    ]);
    setContactDropdownSelector(false);
  };

  const removeAlternateContacts = (values, setFieldValue, i, fieldName) => () => {
    // fieldName = contractorAlternateContacts
    setFieldValue(
      fieldName,
      values[fieldName].filter((_, index) => index != i)
    );
  };

  const { classes, closeModal, type, sheet, openSnackbar } = props;
  const initialValues = {
    notes: sheet.notes || '',
    dotName: '',
    dotEmail: '',
    dotSignature: null,
    dotPhoneNumber: '',
    dot: [
      {
        name: '',
        email: '',
        dotSignature: null,
        phoneNumber: '',
      },
    ],
    contractorName: sheet?.primaryContact?.name || '',
    contractorEmail: sheet?.primaryContact?.email || '',
    contractorPhoneNumber: sheet?.primaryContact?.phoneNumber || '',
    contractorSignature: null,
    contractorAlternateContacts: [],
  };

  const { checkOutsideClockIn } = useCheckOutsideClockIn({
    hours: sheet.hours,
    sheetId: sheet._id,
    workers: sheet.workers,
  });

  return (
    <Dialog
      open
      onClose={closeModal}
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      disableEscapeKeyDown
    >
      <div className={classes.formWrapper} role="presentation">
        <Formik
          validate={validation}
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={async (values, { setSubmitting }) => {
            const data = {
              notes: values.notes.trim(),
            };
            const dot = contactsAdapter([...values.dot]);
            const contractor = [
              {
                name: values.contractorName,
                email: values.contractorEmail,
                image: values.contractorSignature,
                phoneNumber: values.contractorPhoneNumber,
              },
              ...contactsAdapter([...values.contractorAlternateContacts]),
            ];
            data.dot = dot;
            data.contractor = contractor;
            const res = await checkOutsideClockIn();

            if (res.length) setOutsideClockInModal(res);
            else {
              try {
                const res = await $api.post(
                  `${process.env.REACT_APP_BASE_URL}/sheets/${sheet._id}/submit`,
                  data,
                  getOptions()
                );
                dispatch(worklogsPageActions.setForceUpdate(true));
                dispatch(currentWorklogActions.setWorklogSheet(res.data));
                emitEvent(EVENT_NAMESPACES.sheets, SHEET_EVENTS.submit, sheet._id);
                openSnackbar('success', 'Successfully Submitted!');
                closeModal();
              } catch (e) {
                openSnackbar('error', e?.response?.data?.message?.join('\n') || e.mesage); //.join('\n')
                // openSnackbar('error', 'On Job Time is not within Clock-in Time range');
              } finally {
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setSubmitting,
          }) => (
            <PageLoader loading={isSubmitting}>
              <Form autoComplete="off" className={classes.formFlex}>
                <div>
                  <Typography variant="h3" style={{ marginBottom: '10px' }}>
                    {type} sheet
                  </Typography>

                  <IconButton
                    className={classes.closeButton}
                    onClick={closeModal}
                    aria-label="Close"
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>

                  <SubmitDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    openSignatureModal={openSignatureModal}
                    removeSignature={removeSignature}
                    contractorsAlternateContacts={contractorsAlternateContacts}
                    handleAppendContact={handleAppendContact}
                    removeAlternateContacts={removeAlternateContacts}
                    setContactDropdownSelector={setContactDropdownSelector}
                    contactDropdownSelector={contactDropdownSelector}
                    handleAppendDOTContact={handleAppendDOTContact}
                  />
                </div>

                <div className={classes.buttonHolder}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={closeModal}
                    disabled={isSubmitting}
                    disableTouchRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={classes.saveButton}
                    disabled={isSubmitting}
                    disableTouchRipple
                  >
                    Submit
                  </Button>
                </div>

                {signatureModal.isOpen && (
                  <SignatureModal
                    closeModal={closeSignatureModal}
                    submitModal={setSignature(signatureModal.type, setFieldValue)}
                    type={signatureModal.type}
                  />
                )}
                {outsideClockInModal && (
                  <OutsideClockInModal
                    users={outsideClockInModal}
                    closeModal={closeOutsideClockInModal(setSubmitting)}
                    submitModal={submitOutsideClockInModal(setSubmitting, values)}
                  />
                )}
              </Form>
            </PageLoader>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

SubmitModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  sheet: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(SubmitModal);
