import { useSearchParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { parse, format } from 'date-fns';
import { getOptions } from 'helpers/getOptions';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import $api from 'http/index';
import { GLOBAL_DATE_FORMAT, globalBEDateFormat, convertTime } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const ScheduleReport = ({ classes }) => {
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const [printWorklogs, setPrintWorklogs] = useState([]);
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;

  const startTimeHelper = (itemTime, worklogTime) => {
    const time = itemTime ? itemTime : worklogTime;
    if (!time?.hour) return '';

    const is12Format = getTimeFormatHook().is12Format;
    const startTime = convertTime(time, is12Format);

    return startTime;
  };

  const currentDay = queryParams.get('date')
    ? format(
        parse(queryParams.get('date'), globalBEDateFormat, new Date()),
        GLOBAL_DATE_FORMAT.fullDateWithDay
      )
    : format(
        parse(queryParams.get('gridDate'), globalBEDateFormat, new Date()),
        GLOBAL_DATE_FORMAT.fullDateWithDay
      );

  useEffect(() => {
    const getWorklogs = async () => {
      const result = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/sheets/schedule/schedule-report${location.search}`,
        getOptions()
      );
      setPrintWorklogs(result.data);
      setTimeout(() => window.print(), 300);
    };
    getWorklogs();
  }, []);

  return (
    <>
      <div class={classes.root}>
        <h3 class={classes.textCenter}>Daily Schedule Report</h3>
        <h3 class={classes.textCenter}>{currentDay}</h3>
        <div>
          {printWorklogs.map((worklog) => (
            <div class="worklog-container">
              <header class={classes.worklogHeader}>
                <div class={classes.worklogHeaderItem}>
                  <h5 class="text-left name">
                    <strong>
                      {worklog?.project?.contractor?.name}. {worklog?.canceledAt ? 'CANCELED' : ''}
                    </strong>
                  </h5>
                </div>
                <div class={classes.worklogHeaderItemC}>
                  <h5 class="text-center">
                    <strong>Start Time</strong>
                  </h5>
                </div>
                <div class={classes.worklogHeaderItemC}>
                  <h5 class="text-center">
                    <strong>On Job Time</strong>
                  </h5>
                </div>
                <div class={classes.worklogHeaderItemC}>
                  <h5 class="text-center">
                    <strong>Equipment</strong>
                  </h5>
                </div>
              </header>
              <div class={classes.infoBlock}>
                <div class="column large-9 ">
                  {worklog.workers?.map((worker) => (
                    <div class={classes.itemRow}>
                      <div class={classes.rowItems}>
                        <span>{worker.username}</span>
                      </div>
                      <div class={classes.rowItemsC}>
                        <span>{startTimeHelper(worker?.startTime, worklog.startTime)}</span>
                      </div>
                      <div class={classes.rowItemsC}>
                        <div>
                          {Array.isArray(worker.hours) && worker.hours.length ? (
                            worker.hours.map((time) => (
                              <div>
                                {format(new Date(time.start), formatForTimePars)} -{' '}
                                {format(new Date(time.end), formatForTimePars)}
                              </div>
                            ))
                          ) : (
                            <div>
                              {format(new Date(worklog.hours.start), formatForTimePars)} -{' '}
                              {format(new Date(worklog.hours.end), formatForTimePars)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class={classes.rowItemsC}></div>
                    </div>
                  ))}
                </div>
                <div class="column large-3 ">
                  {worklog.equipment.map((item) => (
                    <div class={classes.itemRow}>
                      <div class={classes.rowItems}></div>
                      <div class={classes.rowItemsC}>
                        <span>{startTimeHelper(null, worklog.startTime)}</span>
                      </div>
                      <div class={classes.rowItemsC}>
                        {Array.isArray(item?.hours) && item?.hours?.length ? (
                          item.hours.map(hours => (
                            <div>
                              {format(new Date(hours?.start), formatForTimePars)} -{' '}
                              {format(new Date(hours?.end), formatForTimePars)}
                            </div>
                          ))
                        ) : (
                          <div>
                            {format(new Date(worklog.hours?.start), formatForTimePars)} -{' '}
                            {format(new Date(worklog.hours?.end), formatForTimePars)}
                          </div>
                        )}
                      </div>
                      <div class={classes.rowItemsC}>
                        <span>{item.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(ScheduleReport);
