import { generateHoursObject } from 'helpers/helpers';
import { shortDate } from 'helpers/_date-helpers';

export const getFormattedHoursRange = (sheet, getTimeFormatHook) => {
  const { createdAt, hours } = sheet;
  const hoursToUse = hours;

  if (!hoursToUse) return;

  const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
    generateHoursObject(hoursToUse.start, hoursToUse.end, createdAt, getTimeFormatHook);

  const startObj = [null, hourStart, minuteStart, amPmStart];
  const endObj = [null, hourEnd, minuteEnd, amPmEnd];
  const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
    createdAt,
    dayStart,
    getTimeFormatHook().localDayMonth
  )}`;
  const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
    createdAt,
    dayEnd,
    getTimeFormatHook().localDayMonth
  )}`;

  return `${start} \u2013 ${end}`;
};