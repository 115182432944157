import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoveIcon from '@material-ui/icons/ArrowForward'; // Example icon for 'Move To'
import DuplicateIcon from '@material-ui/icons/FileCopy'; // Example icon for 'Duplicate To'
import DeleteIcon from '@material-ui/icons/Delete'; // Icon for 'Delete'
import CloseIcon from '@material-ui/icons/Close'; // Icon for 'Cancel'
import schedulePageActions from 'store/schedule/schedulePageActions';
import FiltersBar from 'components/FiltersBar/FiltersBar';
import { getFutureSheets } from 'store/schedule/schedulePageOperation';
import PublishedIcon from 'assets/images/sand-timer-outlined-blue.svg';
import SubmittedIcon from 'assets/images/done-outlined-green.svg';
import DraftIcon from 'assets/images/stop-outlined-red.svg';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import styles from './styles';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { createWorklogName } from '../ScheduleEvent/helpers/createWorklogName';
import { useResourceActions } from './hooks/useResourceActions';
import { getFormattedHoursRange } from './hooks/getFormattedHoursRange';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';


const PER_PAGE = 40;

const ResourceActionsBar = ({ classes }) => {
  const dispatch = useDispatch();
  const getTimeFormatHook = useTimeSettingsFormat();

  const selectedResources = useSelector((state) => state.schedule.selectedResources);
  const [isMove, setIsMove] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(null);
  const [worklogsToDisplay, setWorklogsToDisplay] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [hasMoreWorklogs, setHasMoreWorklogs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const selectedCount = selectedResources?.workers?.length + selectedResources?.equipment?.length;
  const open = useMemo(() => !!selectedCount, [selectedCount]);

  const loadMore = (page = 0) => isLoading
    ? null
    : getFutureSheets({ searchValue, skip: page * PER_PAGE, limit: PER_PAGE })
      .then(({ sheets, hasMore }) => {
        if (page === 0) {
          setWorklogsToDisplay((sheets || []).filter(sheet => sheet._id !== selectedResources.sheetId));
        } else {
          setWorklogsToDisplay(worklogsToDisplay.concat((sheets || []).filter(sheet => sheet._id !== selectedResources.sheetId)))
        }
        setHasMoreWorklogs(hasMore);
      })
      .then(() => setIsLoading(false));

  useEffect(() => {
    if (open && !isLoading) {
      loadMore()
      setIsLoading(true)
    }
  }, [searchValue])

  const onMove = () => {
    setIsLoading(true);
    loadMore();
    setIsMove(true);
  }

  const onDuplicate = () => {
    setIsLoading(true);
    loadMore();
    setIsDuplicate(true);
  }

  const onCancel = () => {
    setIsMove(null);
    setIsDuplicate(null);
    setHasMoreWorklogs(true);
    setSearchValue('');

    dispatch(schedulePageActions.deselectAllResources());
  }

  const onMoveDuplicateCancel = () => {
    setIsMove(null);
    setIsDuplicate(null);
    setHasMoreWorklogs(true);
    setSearchValue('');
  }

  const { handleMove, handleDuplicate, handleRemove, OverlappingModalComponent, isLoading: isResourceActionsLoading } = useResourceActions(() => {}, onCancel, dispatch);

  const onDelete = () => {
    handleRemove().then(onCancel);
  }

  const handleUpdateSearchValue = debounce((value) => {
    setHasMoreWorklogs(true);
    setSearchValue(value);
  }, 400)

  if (!open) return null;

  const handleWorklogClick = (sheetId) => (e) => {
    e.preventDefault();

    if (isMove) handleMove(sheetId);
    if (isDuplicate) handleDuplicate(sheetId);
  }

  const renderLocation = (locationId, index, worklog) => {
    const location = (worklog.sheetLocations || []).concat(worklog.project?.locations || []).find(l => l._id === locationId);
    if (!location) return null;

    return (
      <Typography className={classes.worklogsListItemLocation}>
        Project Location {index + 1}: <span className={classes.worklogsListItemLocationName}>{location.name}</span>
      </Typography>
    )
  }

  const renderWorklogShowMoreLocations = (worklog) => {
    if (worklog.locations?.length <= 2) return null;

    const onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setWorklogsToDisplay(worklogsToDisplay
        .map(w => w._id === worklog._id ? ({ ...w, showMoreLocations: !worklog.showMoreLocations }) : w)
      )
    }

    if (worklog.showMoreLocations) return (
      <div className={classes.worklogListItemProjectLocationShowButton} onClick={onClick}>
        <Typography variant="body1">Show less</Typography>
        <ExpandLessIcon />
      </div>
    );

    return (
      <div className={classes.worklogListItemProjectLocationShowButton} onClick={onClick}>
        <Typography variant="body1">Show more</Typography>
        <ExpandMoreIcon />
      </div>
    )
  }

  return (
    <>
      <Box
        className={classes.modal}
      >
        <div className={classes.bar}>
          <div className={classes.info}>
            <div className={classes.selectedCount}>{selectedCount}</div>
            <Typography variant="body1" className={classes.resourceSelected} >Selected</Typography>
          </div>

          {/* Action Buttons */}
          <div className={classes.actions}>
            <Button
              variant="text"
              startIcon={<MoveIcon />}
              onClick={onMove}
              className={classes.actionButton}
            >
              Move to
            </Button>
            <Button
              variant="text"
              startIcon={<DuplicateIcon />}
              onClick={onDuplicate}
              className={classes.actionButton}
            >
              Duplicate to
            </Button>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              onClick={onDelete}
              className={classes.actionButton}
            >
              Delete
            </Button>
          </div>

          {/* Cancel (cross icon) */}
          <IconButton onClick={onCancel} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </div>
      </Box>

      {(isMove || isDuplicate) && (
        <Box className={classes.worklogsListBox}>
          <div className={classes.worklogsListContainer}>

            <div className={classes.worklogsListHeader}>
              <Typography variant="body1" className={classes.worklogsListHeaderText}>Select Work Log</Typography>

              <IconButton onClick={onMoveDuplicateCancel} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </div>

            <FiltersBar
              openDrawer={() => {}}
              updateSearchValue={handleUpdateSearchValue}
              isDynamicSearch={true}
              noFilters
            />

            <div className={classes.worklogsList}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                loading={isLoading}
                hasMore={hasMoreWorklogs}
                threshold={300}
                useWindow={false}
                loader={<span style={{ fontSize: 14 }}>Loading...</span>}
              >
                  {worklogsToDisplay.map(worklog => (
                    <div className={classes.worklogsListItem} key={worklog._id} onClick={handleWorklogClick(worklog._id)}>
                      <div className={classes.worklogsListItemIcon}>
                        {!worklog.published && (<img src={DraftIcon} alt="Draft" />)}
                        {worklog.published && !worklog.submittedAt && (<img src={PublishedIcon} alt="Published" />)}
                        {worklog.submittedAt && (<img src={SubmittedIcon} alt="Canceled" />)}
                      </div>
                      <div className={classes.worklogsListItemInfo}>
                        <Typography variant="body1" className={classes.worklogsListItemContractor}>{worklog.project?.contractor?.name}</Typography>
                        <Typography variant="body1" className={classes.worklogsListItemName}>{createWorklogName(worklog)}</Typography>
                        <Typography variant="body1" className={classes.displayFlex}>{getFormattedHoursRange(worklog, getTimeFormatHook)}</Typography>
                        {(worklog.locations || []).slice(0, 2).map((locationId, index) => renderLocation(locationId, index, worklog))}
                        {((worklog.locations || []).length > 2 && worklog.showMoreLocations) && (
                          (worklog.locations || []).slice(2).map((locationId, index) => renderLocation(locationId, index + 2, worklog))
                        )}
                        {renderWorklogShowMoreLocations(worklog)}
                      </div>
                      {(!!worklog.workers?.filter(worker => worker.foreman)?.length) && (
                        <div className={classes.worklogListItemCL}>
                          <Typography variant="body1" className={classes.worklogListItemCLLabel}>CL:</Typography>
                          <div className={classnames(classes.displayFlex, classes.flexColumn, classes.gap1)}>
                            {worklog.workers?.filter(worker => worker.foreman)?.map(worker => (
                              <div
                              className={classnames(
                                classes.displayFlex,
                                classes.flexRow,
                                classes.justifyStart,
                                classes[worker.shifts.timeOfDay.toLowerCase()],
                              )}
                              >
                                <div
                                  className={classnames(
                                    classes.photoWrapper,
                                    classes[`${worker.shifts.timeOfDay.toLowerCase()}Border`]
                                  )}
                                >
                                  <img src={photoPlaceholder} className={classes.photo} />
                                </div>
                                <Typography
                                  variant="body1"
                                  color="textPrimary"
                                  className={classnames(
                                    classes.username,
                                    classes[worker.shifts.timeOfDay.toLowerCase()]
                                  )}
                                >
                                  {worker.username}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
            {!!isResourceActionsLoading && (
              <div className={classes.resourceActionsLoadingWrapper}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Box>
      )}

      <OverlappingModalComponent />
    </>
  )
}

export default withStyles(styles)(ResourceActionsBar);